* {
  padding: 0;
  margin: 0;
  border: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
:focus,
:active {
  outline: none;
}
a:focus,
a:active {
  outline: none;
}
nav,
footer,
header,
aside {
  display: block;
}
html,
body {
  height: 100%;
  width: 100%;
  font-size: 100%;
  line-height: 1;
  font-size: 14px;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
input,
button,
textarea {
  font-family: inherit;
}
input::-ms-clear {
  display: none;
}
button {
  cursor: pointer;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}
a,
a:visited {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
ul li {
  list-style: none;
}
img {
  vertical-align: top;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

body {
  margin: 0;
  font-family: Roboto, RoadRadio, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',*/
/*    monospace;*/
/*}*/

@font-face {
  font-family: 'RoadRadio';
  src: url('../public/fonts/roadradio/RoadRadio-Bold.woff2') format('woff2'),
    url('../public/fonts/roadradio/RoadRadio-Bold.woff') format('woff'),
    url('../public/fonts/roadradio/RoadRadio-Bold.svg#RoadRadio-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RoadRadio';
  src: url('../public/fonts/roadradio/RoadRadio-Black.eot');
  src: url('../public/fonts/roadradio/RoadRadio-Black.eot?#iefix')
      format('embedded-opentype'),
    url('../public/fonts/roadradio/RoadRadio-Black.woff2') format('woff2'),
    url('../public/fonts/roadradio/RoadRadio-Black.woff') format('woff'),
    url('../public/fonts/roadradio/RoadRadio-Black.ttf') format('truetype'),
    url('../public/fonts/roadradio/RoadRadio-Black.svg#RoadRadio-Black')
      format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RoadRadio';
  src: url('../public/fonts/roadradio/RoadRadio-Thin.eot');
  src: url('../public/fonts/roadradio/RoadRadio-Thin.eot?#iefix')
      format('embedded-opentype'),
    url('../public/fonts/roadradio/RoadRadio-Thin.woff2') format('woff2'),
    url('../public/fonts/roadradio/RoadRadio-Thin.woff') format('woff'),
    url('../public/fonts/roadradio/RoadRadio-Thin.ttf') format('truetype'),
    url('../public/fonts/roadradio/RoadRadio-Thin.svg#RoadRadio-Thin')
      format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RoadRadio';
  src: url('../public/fonts/roadradio/RoadRadio.eot');
  src: url('../public/fonts/roadradio/RoadRadio.eot?#iefix')
      format('embedded-opentype'),
    url('../public/fonts/roadradio/RoadRadio.woff2') format('woff2'),
    url('../public/fonts/roadradio/RoadRadio.woff') format('woff'),
    url('../public/fonts/roadradio/RoadRadio.ttf') format('truetype'),
    url('../public/fonts/roadradio/RoadRadio.svg#RoadRadio') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RoadRadio';
  src: url('../public/fonts/roadradio/RoadRadio-Light.eot');
  src: url('../public/fonts/roadradio/RoadRadio-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../public/fonts/roadradio/RoadRadio-Light.woff2') format('woff2'),
    url('../public/fonts/roadradio/RoadRadio-Light.woff') format('woff'),
    url('../public/fonts/roadradio/RoadRadio-Light.ttf') format('truetype'),
    url('../public/fonts/roadradio/RoadRadio-Light.svg#RoadRadio-Light')
      format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'RoadRadio';
  src: url('../public/fonts/roadradio/RoadRadio-Bold_1.eot');
  src: url('../public/fonts/roadradio/RoadRadio-Bold_1.eot?#iefix')
      format('embedded-opentype'),
    url('../public/fonts/roadradio/RoadRadio-Bold_1.woff2') format('woff2'),
    url('../public/fonts/roadradio/RoadRadio-Bold_1.woff') format('woff'),
    url('../public/fonts/roadradio/RoadRadio-Bold_1.ttf') format('truetype'),
    url('../public/fonts/roadradio/RoadRadio-Bold_1.svg#RoadRadio-Bold')
      format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
